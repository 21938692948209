<template>
  <div class="mx-8">
    <div class="text-center justify-center mt-16 pt-8 mx-auto" style="max-width: 450px">
      <div style="max-width: 287px" class="mx-auto pb-8">
        <p class="mb-0 font-weight-bold" style="color: #66BB6A">{{$t('prices.price_calculator.title')}}</p>
        <h2>{{$t('prices.price_calculator.header')}}</h2>
        <p>{{$t('prices.price_calculator.text')}}</p>
      </div>

      <Calculator></Calculator>
    </div>
  </div>

</template>

<script>
import Calculator from "./Calculator";

export default {
  name: "PricesCalculator",
  components: {
    Calculator
  }
}
</script>

<style scoped>

</style>