<template>
  <div>
    <div
        class="px-8 py-8 grey lighten-4"
        style="max-width: 400px; border-radius: 25px; min-width: 290px; position: relative; z-index: 2; margin: 0 auto"
    >
      <h3 class="mb-4">{{$t('calculator.title')}}</h3>
      <v-row class="mt-0">
        <v-col>
          <p class="body-2 mt-2" style="max-width: 65px">{{$t('calculator.ticket_price')}}</p>
        </v-col>
        <v-col class="float-right">
          <v-text-field
              v-model="ticketPrice"
              filled
              solo
              type="number"
              class="right-input"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <h3>€ {{ calculateKumschoFees() }}</h3>
          <p class="body-2" style="max-width: 50px;">{{$t('calculator.kumscho_fees')}}</p>
        </v-col>
        <v-col class="text-right align-right">
          <h3>€ {{ calculateProviderFees() }}</h3>
          <p class="body-2">{{$t('calculator.provider_fees')}}</p>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <p class="body-2" style="max-width: 50px">{{$t('calculator.costs')}}</p>
        </v-col>
        <v-col>
          <h3 class="green--text text-right">€ {{ parseInt(ticketPrice).toFixed(2) }}</h3>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <p class="body-2" style="max-width: 50px">{{$t('calculator.payout')}}</p>
        </v-col>
        <v-col>
          <h3 class="green--text text-right">€ {{ calculatePayout() }}</h3>
        </v-col>
      </v-row>
    </div>


  </div>


</template>

<script>
export default {
  name: "Calculator",
  data: () => ({
    ticketPrice: 30
  }),
  methods: {
    calculateKumschoFees(){
      let kumschoFee = ((this.ticketPrice / 100 * 2) + 1).toFixed(2)
      return kumschoFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    calculateProviderFees(){
      let providerFee = (this.ticketPrice / 100 * 3).toFixed(2)
      return providerFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    calculatePayout(){
      let providerFee = (parseInt(this.ticketPrice) - ((this.ticketPrice / 100 * 3) + (this.ticketPrice / 100 * 2) + 1)).toFixed(2)
      return providerFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
  }
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
  font-weight: 500;
  font-size: 22px;
}
</style>